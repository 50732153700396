import { useEffect } from 'react';
import gsap from 'gsap';

import classes from './Hero.module.css';

const Hero = () => {
    useEffect(() => {
        var timeline = gsap.timeline({ repeat: -1, repeatRefresh: true });

        timeline.set(`#reveal_box`, { opacity: 0, width: 100, clearProps: 'all' });
        timeline.set(`#hero_text2`, { opacity: 0 });
        timeline.set(`#hero_text`, { y: '50px', opacity: 0 });

        timeline.to(`#hero_text`, { y: '0px', opacity: 1, stagger: 0.5 }, '+=1');
        timeline.to(`#hero_text`, { opacity: 0 }, '+=1');

        timeline.to(`#reveal_box`, 0.1, { opacity: 1 });
        timeline.to(`#reveal_box`, 0.2, { width: '100%' }, '+=.5');
        timeline.to(`#reveal_box`, { right: '0' });
        timeline.to(`#hero_text2`, { opacity: 1 });
        timeline.to(`#reveal_box`, 0.2, { width: '0%' });
        timeline.to(`#reveal_box`, 0.2, { width: '100%' }, '+=2');
        timeline.to(`#hero_text2`, 0.1, { opacity: 0 });
        timeline.to(`#reveal_box`, { left: '0' });
        timeline.to(`#reveal_box`, 0.2, { width: 0 });
        timeline.to(`#mouse_scroll`, 0.5, { opacity: 1 });
    }, []);

    return (
        <>
            <div className={classes['hero-container']}>
                <div className={`${classes['pos-abs']} ${classes['flex-cont']} ${classes['text-container1']}`}>
                  <h1 className={classes['hidden-header']}>Leadership, Strategy and Vision</h1>
                    <p id="hero_text" className={classes['hero-text']}>
                        Leadship.
                    </p>
                    <p id="hero_text" className={classes['hero-text']}>
                        Strategy.
                    </p>
                    <p id="hero_text" className={classes['hero-text']}>
                        Vision.
                    </p>
                </div>
                <div className={`${classes['pos-abs']} ${classes['flex-cont-2']} ${classes['text-container2']}`}>
                    <div className={classes['reveal-container']}>
                        <p id="reveal_box" className={classes['reveal-box']}></p>
                        <h1 id="hero_text2" className={classes['hero-text2']}>
                            James White
                        </h1>
                    </div>
                </div>

                <div className={classes['mouse-scroll']} id="mouse_scroll">
                    <div className={classes.mouse}>
                        <div className={classes.wheel}></div>
                    </div>
                    <div>
                        <span className={`${classes['m-scroll-arrows']} ${classes['top-arrow']}`}></span>
                        <span className={`${classes['m-scroll-arrows']} ${classes['bottom-arrow']}`}></span>
                    </div>
                </div>
            </div>
        </>
    );
};

export default Hero;
