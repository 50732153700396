import classes from './Thanks.module.css';

const Thanks = () => {
    return (
        <div className={classes.thanks__container}>
            <image />
            <address className={classes.thanks__box}>
                <h3 className={classes.thanks__header}>James White</h3>
                <div className={classes.thanks__contact}>
                    <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAADpUlEQVR4nO2dTYhOURjH/zMx3miGRMlHPhbMLJlBiNU0O5ai7CZhsvfdUHZYjBTDIKRs1DQL+cosJNTQmHyLRkyhDGVmMBlXp85bb68355z73se5H/9fPet77v9/zz3Pfe5z7gUIIYQQQgghhBBCCLFnNoC9AO4AGAAw+I/4COAhgMMAailyeVRo4UcABCFiDMBRADka4U4lgIshhS+OWzTBnX0RiR/oaOcssGcWgO8RG/AbQD1N8HP1Bzo6aIAd3UIGvKYBdjwTMiAAMIkmmHklaEAdDTBzV9CAJhpgpkvQgGYaYOaUoAH7aYCZPYIGnKEBZjYKGnCDBphZKmjACxpgRlUvR4UMGNFVVmKgV3AWTKP6ZjoEDailAWa2ChpQRwPMNAiJPwZgMg0wUwXgh4ABvRTfnmsCBmyhAfZsj1j8xwAm0AB75kYo/iCAhRTfnUcRiP8VwDKKH46DFD+5daEvOp0lZdLH206ysiHe8yNGPbkOUfz4F+d45XtejDslB0DM7Sqq/3MVhZKjyWIW3OcbL/99oxuEx5Bp1lgY0A+g2vdA08x1CxOO+x5kmlmuF1zTgtzoe6BZfy7oB1Dje6BpZSqADxYmnPM90DRj28LY4nugWW9lHwWw2vdA08o8y0LdAIAZvgeb9VtRL4ApvgebVtotTejmrnkZVLtJj6UJXQDGCY0j0yzQ74FtTLhAE2RYp/s/A8v3BznIo9adRfoCmY4M0GJpQKDXBImn5ZweR1+JC0JtEnmut0ud1RsHm3W5XXVuT0QKaHUwoSfiFFV14D1xOH6peAPgdNI/MHLM4YTVc8LKCI7ZoNsgg4hCFRVPJjVzqwRwyeFkf5ZZtmhwSAJcI7Hpc5WjCfkCXnWMxM+H+vxaIqkAcMTxZPsd3icsBvBZWHwVv5K+v601xP33hGE2/I8rvzAOIeFsc3hOyMdbXW+q8Cx+PmNLPGtDZir3CvqOfIiv4hNSwnyH2lGpJ2gf4qsYRsoKeG2ehKQBBWwC8C0G4mZuBhQyE8DlGAicWQMKF+j3MRA6swbky8dtIdJVGiCwJ+FqDETP3AwoZgWAmzEQX8U7ZJhG/RMJnwbc9i1CHKjXXRg+Utddvk8+TtTo2pLk59UKY0iny6QESwAc0P+vkTJgR6kDk7+Zo2fGlTL+f1Mc57n3LRzj9QubzXrdeOD4Sc5hfeXzU5sRFwHVQr4ewE790qdTZ1gvATzVae9uNhQTQgghhBBCCCGEEDjwBzxaI51GxNSlAAAAAElFTkSuQmCC"
                        alt="Telephone number"
                    />
                    <a href="tel:+306941483576">+30 694 148 3576</a>
                </div>
                <div className={classes.thanks__contact}>
                    <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAADA0lEQVR4nO2cv2tUQRSFPwwoCNqlMKBWWhghVv4VBiy0tbS19F9Qgr+iFrYprYQUWmgfsVghpFfC2kezWJjlypNZCFGT7JuZN3feng8OLAt5mXvPzJzHDgwIIYQQQgghhBBCCCGEEEL8zSLwHNgCdgGbce2GXqwCV3JOmFOh8WMHRZtTNb15FnqVlJPAOwcFWiV6G3qWjMcOirLKtJKq+ZeBPQcFWWVqenYphQErDoqxSvUwhQGfHBRilepjCgNuAtsOirHKtB16l4TTwANlAcd9FX0FnCUD18KyKj27zKk+A9fJzAngLvDdQcHmRCPgPjBHhywArx0Ub4W1DlykIMvAVweNsI71DbiDE2YppMc5QzaWvof0oIuQPUizx12Y8ZAetQjZc8AaCZj85j3tAPoS0utThuxkAu6Ev48mdgnWGtLDFiG7BGwceE40/wuhMz0N6XGLkJ3U9+sfz4vmsBlyq2chPWixwm8AXw55ZjRHDbptSO84aHiKkD3q2dHkKmDBSUjnnkDR5F7Cy4VCOlXIujKghpAeJw5ZdwZ4DulBhpB1a4CnkB5lDFn3BsSE9FoPJkA0llBdhvQQuN1ByFZlQBchPY4I2RwvAdGkHlDOkB4UCNlqDbCEe3TJkK3eAAtnB/embOB54E1Q8/m4zIX/1dV5RTTWoxOnpQwh2ysDrGVIe/45PBorpGGLkC4Rsr01wFqGdNch23sDbMq3HG/nDb0wwI4Z0h5P3HplgIUgfQLM7xvjfPjO45lz7wywoJ/hlXIjfC49npkzwCqRDEAGFJ+FphVQvhGmLah8M6yAlAHIgOKz0LQCyjfCtAWVb4YVkDIAGVB8FppWQPlGmLag8s2wAlIGIAOKz0LTCijfCKt1C/rhoAirVM25dDRbDgqxSrWZwoCnDgqxSvUohQFXnR52m3PthWuek/DSQUFWmZq7pJPRXMP7wUFRVonep766eGLCC21HHLXtrOZo/n4Ww13STcLrFZU/PdgMPUm25wshhBBCCCGEEEIIIYQQgt7wG0vpOF82HYwNAAAAAElFTkSuQmCC"
                        alt="Email address"
                    />
                    <a href="mailto:james@jameswhite.tech">james@jameswhite.tech</a>
                </div>
                <div className={classes.thanks__contact}>
                    <img
                        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAGAAAABgCAYAAADimHc4AAAACXBIWXMAAAsTAAALEwEAmpwYAAADrUlEQVR4nO2dT4hNURzHP6QZjRixUDZIKWaUlcX4t0HClIVsmBLlX6KQlSQLWTDNGH9WZIeSJIXCiqYsbGaU/IkiYWzMyJ8ajk4dNYv33j33jXfPeWe+n/ptpvu+887ve87v3vPuueeCEEIIIYQQQgghhBD+LAS6gX7gG2ASj2+urV1Aa8iO0gicB35HkBQTKGzbzwENIZL/MIIEmEjiQdEmXIig0SayOFtkzR/LZceUiWGgpQgDuiNorIk0Oosw4FkEDTWRRl8RBgxF0FATaQwWYUDoRprIQwYgA4L3QqMRED4RRiUofDJMgIj+HPAYOARsANqB/e5voROXvAE/gE0VdLe62WToBCZrwHYP7SMRJDBJA94D4z20JwHfI0hicgbcyKH/JIIkJmfA9Rz6vREkMTkDXuS40TMYQRKTM8DGWg/tvREkMFkDPgOLKui2u0vV0AlM1gAbP93tzDXAfGfIFuAW8CeC5CVvwFiImhO6gSbykAHIgOC90GgEhE+EUQkqnYQrwFKgCZgMrATuVJGs18A2YKZblTYPOFphIvccOOn+nz12CjARmANsrvI71N05YGcF3WM5dB4BzWV0FgCfRhz7Bejw/DFwHfAxVQMuemjf9dCxPXxGhs56d+xbYG7O9tkVzwMpGuCzbG+Vh46dyPnQnzHzzhoJSRlg19T70OyhZeu+D7bWj4abKRnw4T/qb6QYlssAghowzl1paQQQxoBqn4OoOfVUghqBE8Ab4BfwCtiX4/M7ZACjMqDciXS35+cXywCqNqBS8t65Gp+FnSWrBFGdAYczkjfbQ2OaDKDqEdCZYYAdIVlMkAFUbUBPRvKWeOqoBCED6vIytEcjQAbUBI0ANBP2QSVIJag2qAShEuSDSpBKUJq3JDs8tS5l6PjcsmxKZSbse1N+tYfOKc/v+TRD56CHRltKBtgemcU9D52BCmuC/rHMQ+elxzZjV1MywMauCrrHc+jcdqvaSjHLrQfy0bnsfvEsxZ4q2xi1ATauASvcI6nNruz49PxSmyPZB7+nu1VvNvEH3Eq4PDq97umcqa7mt7nlkyZVA1KPmhO6gSbykAHIgOC90GgEhE+EUQkKnwwTIHQOQAYE74Um5RGgjVspm/yvRRigrYsJu3VxVwTD3EQap4swoDWRzfVMvW5fj3ttR+gGm8jiDAXS4F7bEbrRJpK4H+I9Mg3utR1juRwNu55fePJH0uKWgveNkUvUIdfWziJrvhBCCCGEEEIIIYSg7vkLEZhxMONBftcAAAAASUVORK5CYII="
                        alt="Linkedin"
                    />
                    <p>
                        <a href="https://www.linkedin.com/in/jbwhite1/">jbwhite1</a>{' '}
                    </p>
                </div>
            </address>
        </div>
    );
};

export default Thanks;
