import { useLottie, useLottieInteractivity } from "lottie-react";

const PlayLottieOnHover = ({style, animation}) => {
  const options = {
    animationData: animation,
  };

  const lottieObj = useLottie(options, style);
  const Animation = useLottieInteractivity({
    lottieObj,
    mode: "cursor",
    actions: [
      {
        position: { x: [0, 1], y: [0, 1] },
        type: "loop",
        frames: [0, 48],
      },
      {
        position: { x: -1, y: -1 },
        type: "stop",
        frames: [48],
      },
    ],
  });

  return Animation;
};

export default PlayLottieOnHover;