import AboutContent from './AboutContent';
import AboutAnimation from './AboutAnimation';

import classes from './About.module.css';
import animClasses from './AboutAnimation.module.css';

const TAGS = [
'HTML', 'CSS', 'SCSS', 'JavaScript', 'React', 'Next.js', 'Gatsby', 'node.js', 'GraphQL', 'REST', 'API',
'UI/UX', 'Linux', 'GIT',
'AWS', 'Serverless', 'Containers', 'Azure', 'GCP', 'SaaS', 'Lambda', 'Cognito', 'Firebase', 'CI/CD', 'Fargate', 'CDN',
'REDIS', 'SQL', 'NoSQL', 'Architecture', 'Cypress', 'JSON', 'OAuth'];
const DURATION = 30000;
const ROWS = 5;
const TAGS_PER_ROW = 5;

const random = (min, max) => Math.floor(Math.random() * (max - min)) + min;
const shuffle = (arr) => [...arr].sort(() => 0.5 - Math.random());

const Tag = ({ text }) => (
    <div className={animClasses.tag}>
        <span>#</span> {text}
    </div>
);

const About = () => {
    return (
        <div className={classes.about__container}>
            <AboutContent className={classes.about__content} />
            <div className={`${animClasses.tagList} ${classes.about__anim}`}>
                {[...new Array(ROWS)].map((_, i) => (
                    <AboutAnimation key={i} duration={random(DURATION - 5000, DURATION + 5000)} reverse={i % 2}>
                        {shuffle(TAGS)
                            .slice(0, TAGS_PER_ROW)
                            .map((tag) => (
                                <Tag text={tag} key={tag} />
                            ))}
                    </AboutAnimation>
                ))}
                <div className={animClasses.fade} />
            </div>
        </div>
    );
};

export default About;
