import React, { useState, useRef, useEffect } from 'react';
import gsap, { Power3 } from 'gsap';

import leftArrow from '../../../assets/arrow-left.svg';
import rightArrow from '../../../assets/arrow-right.svg';

// import "reset-css";
import classes from './Projects.module.css';

const projects = [
    {
        name: 'HALO INFINITE: BECOME MASTER CHIEF',
        title: 'React, GSAP, WebGL, NodeJS, Azure Functions',
        image: `${require('../../../assets/Projects/become-master-chief.jpg')}`,
        link: 'https://www.youtube.com/watch?v=K4V85E5B_Vw',
        quote: 'Halo Infinite: Become Master Chief, the immersive digital experience for the Halo Infinite launch, enabling users to inject their face into a Halo trailer to become Master Chief.'
    },
    {
        name: 'NIARRA TRAVEL',
        title: 'React, GSAP, WebGL, PHP, Middleware, AWS, SugarCRM, CraftCMS',
        image: `${require('../../../assets/Projects/niarra-travel.jpg')}`,
        link: 'https://www.niarratravel.com/',
        quote: 'The Niarra Travel website, showcasing their amazing experiences they offer. Allowing for the Niarra team to present customised trips for individuals.'
    },
    {
        name: 'ORANGEFIT',
        title: 'React, GSAP, DatoCMS, Shopify, SendCloud, TrustPilot, AWS, Serverless',
        image: `${require('../../../assets/Projects/orangefit.png')}`,
        link: 'https://orangefit.eu',
        quote: 'Orangefit ecommerce store, for buying all your needs for a fitter and healthier lifestyle. Built on Shopify and utilising DatoCMS to build in richer content.'
    }
];

const Projects = () => {
    let imageList = useRef(null);
    let projectList = useRef(null);

    // const element = document.querySelector('projectImage');
    // const imageWidth = element.style.width;


    let imageWidth = 204;

    const [matches, setMatches] = useState(
      window.matchMedia("(min-width: 768px)").matches
    )

    useEffect(() => {
      window
      .matchMedia("(min-width: 40rem)")
      .addEventListener('change', e => setMatches( e.matches ));
    }, []);

    if (!matches) {
      imageWidth = 204
    } else {
      imageWidth = 340
    }

    const [state, setState] = useState({
        isActive1: true,
        isActive2: false,
        isActive3: false
    });

    useEffect(() => {
        gsap.to(projectList.children[0], { duration: 0, opacity: 1 });
    }, []);

    //Image transition
    const slideLeft = (index, duration, multiplied = 1) => {
        gsap.to(imageList.children[index], {
            duration: duration,
            x: -imageWidth * multiplied,
            ease: Power3.easeOut
        });
    };

    const slideRight = (index, duration, multiplied = 1) => {
        gsap.to(imageList.children[index], {
            duration: duration,
            x: imageWidth * multiplied,
            ease: Power3.easeOut
        });
    };

    const scale = (index, duration) => {
        gsap.from(imageList.children[index], {
            duration: duration,
            scale: 1.2,
            ease: Power3.easeOut
        });
    };

    //Content transition

    const fadeOut = (index, duration) => {
        gsap.to(projectList.children[index], {
            duration: duration,
            opacity: 0
        });
    };

    const fadeIn = (index, duration) => {
        gsap.to(projectList.children[index], {
            duration: duration,
            opacity: 1,
            delay: 1
        });
    };

    const nextSlide = () => {
        if (imageList.children[0].classList.contains('active')) {
            setState({ isActive1: false, isActive2: true });
            //Image transition
            slideLeft(0, 1);
            slideLeft(1, 1);
            scale(1, 1);
            slideLeft(2, 1);
            slideLeft(2, 0);
            fadeOut(0, 1);
            fadeIn(1, 1);
        } else if (imageList.children[1].classList.contains('active')) {
            setState({ isActive2: false, isActive3: true });
            //Image transition
            slideRight(0, 1);
            slideLeft(1, 1, 2);
            slideLeft(2, 1, 2);
            scale(2, 1);
            //content transition
            fadeOut(1, 1);
            fadeIn(2, 1);
        } else if (imageList.children[2].classList.contains('active')) {
            setState({ isActive1: true, isActive3: false });
            //Image transition
            slideLeft(2, 1, 3);
            slideLeft(0, 1, 0);
            slideLeft(1, 0, 0);
            scale(0, 1);
            //content transition
            fadeOut(2, 1);
            fadeIn(0, 1);
        }
    };

    const prevSlide = () => {
        if (imageList.children[0].classList.contains('active')) {
            setState({ isActive1: false, isActive3: true });
            //Image transition
            slideLeft(2, 0, 3);
            slideLeft(2, 1, 2);
            scale(2, 1);
            slideRight(0, 1);
            slideRight(1, 1);
            //content transtion
            fadeOut(0, 1);
            fadeIn(2, 1);
        } else if (imageList.children[1].classList.contains('active')) {
            setState({ isActive2: false, isActive1: true });
            //Image transition
            slideLeft(0, 0);
            slideRight(0, 1, 0);
            slideRight(1, 1, 0);
            slideRight(2, 1, 2);
            scale(0, 1);
            //content transtion
            fadeOut(1, 1);
            fadeIn(0, 1);
        } else if (imageList.children[2].classList.contains('active')) {
            setState({ isActive2: true, isActive3: false });
            slideLeft(2, 1);
            slideLeft(1, 0, 2);
            slideLeft(1, 1);
            scale(1, 1);
            //content transtion
            fadeOut(2, 1);
            fadeIn(1, 1);
        }
    };

    const activeColorClass = `${classes['t-image']} ${state.isActive1 ? classes['color-1'] : ''} ${state.isActive2 ? classes['color-2'] : ''} ${state.isActive3 ? classes['color-3'] : ''}`;

    return (
        <div className={classes['testimonial-section']}>
            <div className={classes['testimonial-container']}>
                <div onClick={prevSlide} className={`${classes.arrows} ${classes.left}`}>
                    <span>
                        <img src={leftArrow} alt="left arrow" />
                    </span>
                </div>
                <div className={classes.inner}>
                    <div className={activeColorClass}>
                        <ul ref={(el) => (imageList = el)}>
                            <li className={state.isActive1 ? 'active' : ''}>
                                <a href={projects[0].link} target="_blank" rel="noreferrer">
                                    <img className={classes.projectImage} src={projects[0].image} alt={projects[0].name} />
                                </a>
                            </li>
                            <li className={state.isActive2 ? 'active' : ''}>
                                <a href={projects[1].link} target="_blank" rel="noreferrer">
                                    <img className={classes.projectImage} src={projects[1].image} alt={projects[0].name} />
                                </a>
                            </li>
                            <li className={state.isActive3 ? 'active' : ''}>
                                <a href={projects[2].link} target="_blank" rel="noreferrer">
                                    <img className={classes.projectImage} src={projects[2].image} alt={projects[0].name} />
                                </a>
                            </li>
                        </ul>
                    </div>
                    <div className={classes['t-content']}>
                        <ul ref={(el) => (projectList = el)}>
                            <li className={state.isActive1 ? 'active' : ''}>
                                <div className={classes['content-inner']}>
                                    <p className={classes.quote}>{projects[0].quote}</p>
                                    <h3 className={classes.name}>{projects[0].name}</h3>
                                    <h4 className={classes.title}>{projects[0].title}</h4>
                                </div>
                            </li>
                            <li className={state.isActive2 ? 'active' : ''}>
                                <div className={classes['content-inner']}>
                                    <p className={classes.quote}>{projects[1].quote}</p>
                                    <h3 className={classes.name}>{projects[1].name}</h3>
                                    <h4 className={classes.title}>{projects[1].title}</h4>
                                </div>
                            </li>
                            <li className={state.isActive3 ? 'active' : ''}>
                                <div className={classes['content-inner']}>
                                    <p className={classes.quote}>{projects[2].quote}</p>
                                    <h3 className={classes.name}>{projects[2].name}</h3>
                                    <h4 className={classes.title}>{projects[2].title}</h4>
                                </div>
                            </li>
                        </ul>
                    </div>
                </div>
                <div className={`${classes.arrows} ${classes.right}`} onClick={nextSlide}>
                    <img src={rightArrow} alt="right arrow" />
                </div>
            </div>
        </div>
    );
};

export default Projects;
