import { useRef } from 'react';

import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/all';

import Hero from './components/Blocks/Hero';
import About from './components/Blocks/About/About';
import Thanks from './components/Blocks/Thanks'
import Footer from './components/Footer';

import classes from './App.module.css';
import Projects from './components/Blocks/Projects/Projects';

function App() {
    const el = useRef(null);
    gsap.registerPlugin(ScrollTrigger);

    return (
        <div ref={el} className={classes.container} id="container">
            <section className={classes.section} id="section">
                <Hero />
            </section>
            <section className={classes.section} id="section">
                <Projects />
            </section>
            <section className={classes.section} id="section">
                <About />
            </section>
            <section className={classes.section} id="section">
                <Thanks />
            </section>
            <Footer />
        </div>
    );
}

export default App;
