import classes from './AboutContent.module.css';

const AboutText = () => {
    return (
        <>
            <div id="about-me" className={classes.about__content}>
                <h2>About Me</h2>
                <p>
                    Working with, enabling and empowering talented teams to create awesome products and solutions drives my motivation as a technical leader. What excites me is being challenged and
                    continuously learning and honing my skill set across technical, leadership and soft skill functions.
                </p>
                <p>
                    My experience creating SaaS solutions for some of the biggest companies in the world has taught me how to balance client demands with the overall vision and roadmap. It has taught
                    me that the best solutions are usually the simplest ones, and that overengineering should be avoided.
                </p>
                <p>
                    During the start-up phase of a company, it is common to take shortcuts to get a product to market quickly, but it is important to plan for resolving the technical debt this
                    creates. While you may not have the time or budget to deliver the ideal architecture and technical design, thinking about the aspects you have to forego upfront can help you get
                    ahead of potential problems that may arise from taking necessary shortcuts.
                </p>
                <p>
                    Looking for a new challenge, I joined a world-renowned digital agency to create award-winning experiences within impossible deadlines. The experience really drove home the
                    importance of an empowered team, to strong and effective decision making, and the leadership skills needed to achieve that. The experience also honed my ability to determine,
                    support and deliver rapid action resolutions. Talking about an issue must lead to action, not just more talk.
                </p>
            </div>
        </>
    );
};

export default AboutText;
