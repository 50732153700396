import React from 'react';

import classes from './AboutAnimation.module.css';

const InfiniteLoopSlider = ({ children, duration, reverse = false }) => {
    return (
            <div
                className={classes.loopSlider}
                style={{
                    '--duration': `${duration}ms`,
                    '--direction': reverse ? 'reverse' : 'normal'
                }}
            >
                <div className={classes.inner}>
                    {children}
                    {children}
                </div>
            </div>
    );
};

export default InfiniteLoopSlider;
