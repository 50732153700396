import PlayLottieOnHover from './Helper/Lottie';

import classes from './Footer.module.css';

import linkedinAnimation from '../assets/linkedin.json';
import githubAnimation from '../assets/github.json';
import mailAnimation from '../assets/mail.json'

const Footer = () => {
    const style = {
        height: 84,
        width: 84
    };

    return (
        <nav>
            <ul className={classes.footer}>
                <li className={classes.link}>
                    <a href="mailto: james@jameswhite.tech">
                        <PlayLottieOnHover animation={mailAnimation} style={style} />
                    </a>
                </li>
                <li className={classes.link}>
                    <a href="https://www.linkedin.com/in/jbwhite1/" target="_blank" rel="noopener noreferrer">
                        <PlayLottieOnHover animation={linkedinAnimation} style={style} />
                    </a>
                </li>
                <li className={classes.link}>
                    <a href="https://github.com/james-white-x" target="_blank" rel="noopener noreferrer">
                        <PlayLottieOnHover animation={githubAnimation} style={style} />
                    </a>
                </li>
            </ul>
        </nav>
    );
};

export default Footer;
